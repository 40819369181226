'use client'
import { TAlertProps } from './Alert.type'
import { Info, AlertTriangle, X, Check } from 'react-feather'
import styles from './Alert.module.css'
import { cn } from 'shared-utils'

const iconMap = {
  info: Info,
  warning: AlertTriangle,
  error: X,
  success: Check,
}

export default function Alert(props: TAlertProps) {
  const { variant = 'info', withIcon = true, description, className, title, onClose, ...rest } = props

  const Icon = iconMap[variant]

  return (
    <div {...rest} data-variant={variant} className={cn(styles.alert, className)}>
      {withIcon && (
        <div className={styles.icon}>
          <Icon />
        </div>
      )}
      <div className={cn([typeof onClose === 'function' && 'pr-6'])}>
        {title && <div className={styles.title}>{title}</div>}
        {description && <div className={styles.description}>{description}</div>}
      </div>
      {typeof onClose === 'function' && (
        <span className={styles.closeIcon} onClick={onClose}>
          <X size={18} />
        </span>
      )}
    </div>
  )
}
