const generateTaxFormulaLabel = ({
  ppnPercentage,
  ppnBmPercentage,
  isLuxuryItem,
}: {
  ppnPercentage?: number
  ppnBmPercentage?: number
  isLuxuryItem?: boolean
}) => {
  const map: Map<string, string> = new Map()

  if (ppnPercentage) {
    map.set(
      'PPN',
      isLuxuryItem ? `PPN = Harga Produk x ${ppnPercentage}%` : `PPN = (11/12 x Harga Produk) x ${ppnPercentage}%`,
    )
  }

  if (ppnBmPercentage) {
    map.set('PPnBM', `PPnBM = Harga Produk x ${ppnBmPercentage}%`)
  }

  return map
}

export default generateTaxFormulaLabel
