import { cn } from 'shared-utils'
import { TFieldValueProps } from './FieldValue.types'
import { Info } from 'react-feather'
import { Tooltip } from '..'

const FieldValue = ({ label, value, classNames, valueTooltip }: TFieldValueProps): JSX.Element => {
  return (
    <div className={cn('text-caption-sm-regular mb-1 flex', classNames?.container)}>
      <span className={cn('min-w-100 text-caption-sm-regular mr-2 w-17', classNames?.label)}>{label}</span>
      <span className={cn('mr-1 w-fit')}>:</span>
      <span className={cn('text-caption-sm-regular mr-1', classNames?.value)}>{value}</span>
      {valueTooltip && (
        <Tooltip title="" content={valueTooltip}>
          <Info className="inline-block min-w-min text-tertiary500" size={16} style={{ padding: '2px' }} />
        </Tooltip>
      )}
    </div>
  )
}

export default FieldValue
