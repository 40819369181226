"use client"
import { useEffect, useRef } from 'react';

type Delay = number | null;
type TimerHandler = (...args: any[]) => void;

const useInterval = (callback: TimerHandler, delay: Delay, timeout?: number) => {
  const savedCallbackRef = useRef<TimerHandler>();

  useEffect(() => {
    savedCallbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = (...args: any[]) => savedCallbackRef.current!(...args);

    if (delay !== null) {
      const intervalId = setInterval(handler, delay) as NodeJS.Timeout;
      if (timeout) {
        setTimeout(() => clearInterval(intervalId), timeout)
      }
      return () => clearInterval(intervalId);
    }
  }, [delay]);
};

export default useInterval;